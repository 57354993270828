import React from "react"

import Layout from "../layout/en"
import SEO from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <div className="not-found">
      <h1>404</h1>
      <span>
        NOT FOUND - You just hit a route that doesn&#39;t exist... the sadness.
      </span>
    </div>
  </Layout>
)

export default NotFoundPage
